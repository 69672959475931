import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "authTokenModal" ];

    showModal(e) {
        if(e && e.stopPropagation) e.stopPropagation();

        let modalController = this.application.getControllerForElementAndIdentifier(
            this.authTokenModalTarget, "components--modal"
        );

        modalController.open();
    }
}
