import $ from 'jquery';
import BaseController from "../base_controller"
import 'select2'
import 'select2/dist/css/select2.css'
import I18n from "../../js/util/i18n.js.erb";

export default class extends BaseController {
    static targets = [
        'searchBox',
        'latitudeField',
        'longitudeField',
        'cityLatitudeField',
        'cityLongitudeField',
        'radiusSelect',
        'cmePointsSelect'
    ];

    connect() {
        let that = this;

        this.$form = $(this.element);
        this.$latitudeField = $(this.latitudeFieldTarget);
        this.$longitudeField = $(this.longitudeFieldTarget);

        this.$cityLatitudeField = $(this.cityLatitudeFieldTarget);
        this.$cityLongitudeField = $(this.cityLongitudeFieldTarget);

        this.$radiusSelect = $(this.radiusSelectTarget);
        this.$cmePointsSelect = $(this.cmePointsSelectTarget);

        this.$searchBox = $(this.searchBoxTarget);
        let placeholder = this.$searchBox.attr('placeholder');

        this.$searchBox.select2({
            theme: 'schwabe',
            width: '100%',
            placeholder: placeholder,
            allowClear: true,
            ajax: {
                url: '/web/events/search_options',
                type: 'get',
                dataType: 'json',
                delay: 250,
                cache: true,
                data: function (params) {
                    return {
                        query: params.term
                    };
                },
                processResults: function (response) {
                    return response;
                }
            }
        });

        this.$searchBox.on('select2:select', (event) => {
            that.setSelectOption(event.params.data);
        });

        this.$searchBox.on('select2:clear', () => {
            that.clearCityCoordinates();
        });

        this.$searchBox.siblings('.select2')
                .find('.select2-selection__rendered')
                .addClass('select2-selection__rendered--with-icon')
                .addClass('select2-selection__rendered--big-text');

        this.$form.on('submit', (event) => {
            event.preventDefault();
            that.submit();

            return false;
        });
    }

    disconnect() {
        this.$searchBox.select2('destroy');
    }

    get cityLocation() {
        if(!this.$searchBox.val()) return;

        return {
            latitude: this.$cityLatitudeField.val(),
            longitude: this.$cityLongitudeField.val()
        }
    }

    get location() {
        return {
            latitude: this.$latitudeField.val(),
            longitude: this.$longitudeField.val()
        }
    }

    set location(location) {
        this.$latitudeField.val(location.latitude);
        this.$longitudeField.val(location.longitude);
    }

    get radius() {
        return this.$radiusSelect.val();
    }

    set radius(radius) {
        let options = this.$radiusSelect.find('option');
        let bestOption = null;
        let nextSmallerOption = null;
        let roundedRadius = Math.ceil(radius / 5) * 5;

        this.$radiusSelect.find('option[data-is-custom="true"]').remove();

        options.each((index, option) => {
            if(!option.value) return;

            let option_radius = parseInt(option.value)
            let matches_existing_option = (option_radius === radius || option_radius === roundedRadius);

            // this assumes that options are ordered
            if(option_radius < radius) {
                nextSmallerOption = option;
            } else if (matches_existing_option) {
                bestOption = option;
            }
        });

        if(!bestOption) {
            bestOption = new Option(roundedRadius + ' km', roundedRadius, true, true);

            $(bestOption).attr('data-is-custom', 'true');

            $(bestOption).insertAfter($(nextSmallerOption));
        }

        this.$radiusSelect.val(bestOption.value);
        this.$radiusSelect.trigger('change');
    }

    get params() {
        return this.$form.serializeArray().filter((item) => {return item.value !== ''; });
    }

    get action() {
        return this.$form.attr('action');
    }

    get hasSearchParams() {
        return !!this.params.length;
    }

    get hasLocationSearch() {
        return this.$latitudeField.val() &&
               this.$longitudeField.val() &&
               this.$radiusSelect.val();
    }

    setSelectOption(option) {
        if(option.type === 'event') {
            window.location = option.path;
        } else {
            this.$latitudeField.val(option.latitude);
            this.$longitudeField.val(option.longitude);

            this.$cityLatitudeField.val(option.latitude);
            this.$cityLongitudeField.val(option.longitude);

            this.radius = 50;
        }
    }

    clearCityCoordinates() {
        this.$cityLatitudeField.val('');
        this.$cityLongitudeField.val('');
    }

    submit () {
        let that = this;

        this.search().then(() => {
            that.trigger('eventSearch:submitted');
        })
    }

    search () {
        let that = this;
        let params = this.params;
        let action = this.action;
        let submitPath = action + "?" + $.param(params);

        return new Promise((resolve, reject) => {
            $.get(submitPath)
                .then((results) => {
                    that.trigger('eventSearch:searchSuccess', results, params);
                    resolve(results);
                })
                .catch((error) => {
                    console.log("Failed to search", error);
                    that.trigger('eventSearch:searchError', error, params);
                    reject(error);
                });
        });
    }
}
