import { Controller } from "stimulus";
import $ from "jquery";
import createIcon from '../../js/icon';
import fallbackImage from 'images/media/missing_image.png';
import "../../js/util/csrf-token";

export default class extends Controller {
    static targets = [ "mediaImage", "favorButton", "mediaVideo" ];

    connect() {
        let that = this;

        this.showUrl = this.data.get('show-url');

        if(this.hasMediaImageTarget) {
            this.$mediaImage = $(this.mediaImageTarget);
            this.$mediaImage.on('error', () => {
                that.$mediaImage.attr('src', fallbackImage);
            });
        }

        if(this.hasFavorButtonTarget) {
            this.isFavored = this.data.get('favored') === 'true';
            this.favorUrl = this.data.get('favor-url');
            this.unfavorUrl = this.data.get('unfavor-url');

            this.$favorButton = $(this.favorButtonTarget);

            if(this.isFavored) {
                this.showFavoredIcon()
            } else {
                this.showUnfavoredIcon()
            }
        }

        if(this.$favorButton && this.favorUrl && this.unfavorUrl) {
            this.favorable = true;
        }

        this.removeOnUnfavor = this.data.get('remove-on-unfavor') === 'true';
    }

    showMedium() {
        window.location = this.showUrl;
    }

    playVideo() {
        if(!this.hasMediaVideoTarget) return;

        let videoController = this.application.getControllerForElementAndIdentifier(
            this.mediaVideoTarget, "components--video"
        );

        videoController.play();
    }

    toggleFavor(event) {
        event.stopPropagation();

        if(!this.favorable) return;

        if(this.isFavored) {
            this.unfavor();
        } else {
            this.favor();
        }
    }

    favor() {
        if(!this.favorable) return;
        this.favorable = false;

        let that = this;

        $.post(this.favorUrl)
            .then(() => {
                that.isFavored = true;
                that.showFavoredIcon();
            })
            .catch((error) => {
                console.log("Failed to favor medium", error)
            })
            .always(() => {
                that.favorable = true
            });
    }

    unfavor() {
        if(!this.favorable) return;
        this.favorable = false;

        let that = this;

        $.post(this.unfavorUrl)
            .then(() => {
                that.isFavored = false;
                that.showUnfavoredIcon();

                if(that.removeOnUnfavor) {
                    $(that.element).remove();
                }
            })
            .catch((error) => {
                console.log("Failed to unfavor medium", error)
            })
            .always(() => {
                that.favorable = true
            });
    }

    showFavoredIcon() {
        if(!this.$favorButton) return;

        this.$favorButton.empty();
        this.$favorButton.append(createIcon('ic-like-fill').addClass('media__favored-icon'));
    }

    showUnfavoredIcon() {
        if(!this.$favorButton) return;

        this.$favorButton.empty();
        this.$favorButton.append(createIcon('ic-like').addClass('media__unfavored-icon'));
    }
}
