import $ from 'jquery';
import { debounce } from 'throttle-debounce';
import BaseController from "../base_controller"
import I18n from '../../js/util/i18n.js.erb';

import CookieConsent from '../../js/util/cookie_consent';

export default class extends BaseController {
    static targets = ['search', 'tabs', 'map', 'list', 'consentInfo', 'header'];

    connect() {
        let that = this;
        this.$element = $(this.element);

        $(this.consentInfoTarget).show();
        $(this.headerTarget).hide();
        $(this.tabsTarget).hide();

        CookieConsent.onConsent().then(() => {
            $(this.consentInfoTarget).hide();
            $(this.headerTarget).show();
            $(this.tabsTarget).show();

            this.$list = $(this.listTarget);

            setTimeout(() => {
                that.searchController = that.getControllerForTarget('search', 'components--event-search');
                that.mapController = that.getControllerForTarget('map', 'components--map');
                that.tabsController = that.getControllerForTarget('tabs', 'components--tabs');

                that.connectSearch();
                that.connectMap();

                that.mapController.waitForGoogleMaps().then(() => {
                    that.finishConnect();
                })
            });
        });
    }

    finishConnect() {
        let that = this;

        if(this.searchController.hasSearchParams) {
            this.searchController.submit();
        } else {
            this.mapController.resetView();
            this.searchController.location = this.mapController.location;
            this.searchController.radius = this.mapController.radius;

            this.debouncedSearch();
        }
    }

    connectSearch() {
        let that = this;

        this.debouncedSearch = debounce(250, () => {
            that.searchController.search();
        });

        this.searchController.on('eventSearch:submitted', () => {
            that.mapController.setView(
                that.searchController.location,
                that.searchController.radius,
                that.searchController.cityLocation
            )
        });

        this.searchController.on('eventSearch:searchSuccess', (results, params) => {
            that.setResults(results, params);
        })
    }

    connectMap() {
        let that = this;

        this.mapController.on('map:radius_changed', debounce(250, (radius) => {
            that.searchController.radius = radius;

            that.debouncedSearch();
        }));

        this.mapController.on('map:center_changed', debounce(250, (latitude, longitude) => {
            that.searchController.location = {
                latitude: latitude,
                longitude: longitude
            };

            that.debouncedSearch();
        }));
    }

    setResults(results, params) {
        this.setBrowserHistoryState();

        let eventMarkers = results.events.map((event) => {
            return {
                id: event.id,
                position: event.geo_position,
                title: event.name,
                link: event.path,
                isOnline: event.is_online
            };
        });

        this.$list.html(results.rendered_events);
        this.mapController.setMarkers(eventMarkers);
    }

    setBrowserHistoryState() {
        if (history.pushState) {
            let queryParams = $.param(this.searchController.params);
            let updatedUrl = window.location.origin + window.location.pathname + '?' + queryParams;

            window.history.replaceState({ path:updatedUrl },'', updatedUrl);
        }
    }
}
