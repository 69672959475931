import $ from 'jquery';
import moment from 'moment/moment';
import fallbackAttachment from 'images/chat/missing_image.png';
import fallbackAvatar from 'images/chat/avatar_placeholder.png';
import fallbackAvatarMale from 'images/chat/avatar_placeholder_male.png';

export default function Message(data) {
    let self = this;

    this.type = null;
    this.id = null;
    this.text = null;
    this.senderType = null;
    this.createdAt = null;
    this.isRead = null;
    this.sender = null;
    this.medium = null;

    if (data) {
        for (let [key, value] of Object.entries(data)) {
            self[key] = value;
        }
    }

    this.isFromUser = function() {
        return this.senderType === "user";
    };

    this.hasMediumAttached = function() {
        return this.medium !== null;
    };

    this.getAvatarUrl = function() {
        if(this.isFromUser()) return null;

        return this.sender.profileImageUrl;
    };

    this.getFallbackAvatar = function() {
        if(this.isFromUser()) return null;

        if(this.sender.gender === 'male') {
            return fallbackAvatarMale;
        }
        else {
            return fallbackAvatar;
        }
    };

    this.getFullName = function () {
        if(this.isFromUser()) return null;

        return [this.sender.title, this.sender.firstName, this.sender.lastName].join(' ');
    };

    this.getMoment = function() {
        if(!this.createdAt) return null;

        return moment(this.createdAt);
    };

    this.render = function() {
        let $message = $('<div>', { class: 'message' });
        let $body    = $('<div>', { class: 'message__body' });

        let $time    = $('<div>', { class: 'message__time' }).html(this.getMoment().format('HH:mm'));
        let $text    = $('<p>',   { class: 'message__text' }).html(this.text);

        let $attachment = null;

        if(this.hasMediumAttached()) {
            $attachment = $('<img>', { class: 'message__attachment', src: this.medium.thumbnailUrl });

            $attachment.on('error', () => {
                $attachment.attr('src', fallbackAttachment);
            });

            let $title = $('<div>', { class: 'message__attachment-title' }).html(this.medium.title);
            let $subtitle = $('<div>', { class: 'message__attachment-subtitle'}).html('Kommentar');

            $body.append([$attachment, $title, $subtitle]);
        }

        if(this.isFromUser()) {
            $message.addClass('message--right');
            $body.addClass('message__body--right');
            $time.addClass('message__time--right');

            if($attachment) {
                $attachment.addClass('message__attachment--right');
            }
        } else {
            let $avatar = $('<img>', { class: 'message__avatar', src: this.getAvatarUrl() });

            if(!this.getAvatarUrl()) {
                $avatar.attr('src', this.getFallbackAvatar());
            }

            $avatar.on('error', () => {
                $avatar.attr('src', this.getFallbackAvatar());
            });

            $message.append($avatar);
        }

        $body.append($text);
        $message.append([$body, $time]);

        return $message;
    };
}
