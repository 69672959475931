import $ from 'jquery';

export default function UnreadBadge(linkElement, configuration) {
    let self = this;
    let updateInterval = null;

    let config = $.extend(true, {
        apiUrl: null,
        pollingInterval: null
    }, configuration || {});

    this.initialize = function() {
        this.$link = $(linkElement);
        this.$badge = $('<div>', {class: 'chat__unread-badge'}).html('0');
        this.$link.append(this.$badge);

        this.$badge.hide();
        this.update();

        updateInterval = window.setInterval(() => self.update(), config.pollingInterval);
    };

    this.update = function () {
        $.get(config.apiUrl)
            .done((count) => {
                self.$badge.html(count);

                if(count > 0) {
                    self.$badge.show();
                } else {
                    self.$badge.hide();
                }
            })
    };

    this.destroy = function() {
        window.clearInterval(updateInterval);
        this.$badge.remove();
    };
}
