import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ 'closeButton' ];

    connect() {
    }

    hide() {
      this.element.style.display = 'none';
    }
}
