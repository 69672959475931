import { Controller } from "stimulus";
import $ from "jquery";
import createIcon from '../../js/icon';

export default class extends Controller {
    static targets = [ "input" ];

    connect() {
        this.$element = $(this.element);
        this.$input = $(this.inputTarget);

        let info = this.data.get('info');
        let error = this.data.get('error');

        if(error) {
            this.addError(error);
        } else if(info) {
            this.addInfo(info);
        }
    }

    setIcon($icon) {
        this.$icon = $icon;

        this.$element.append(this.$icon);
        this.$icon.show();
    }

    addInfo(info) {
        let that = this;

        this.$info = $('<div>', { class: "input-group__info"}).html(info);
        this.hideInfo();

        let $infoIcon = createIcon('ic-textfield-help-small').addClass('input-group__icon input-group__icon--info');

        $infoIcon.on('click', () => { that.toggleInfo() });

        this.$element.append(this.$info);
        this.setIcon($infoIcon);
    }

    toggleInfo() {
        if(!this.$info) return;

        if(this.$info.is(':visible')) {
            this.hideInfo();
        } else {
            this.showInfo();
        }
    }

    showInfo() {
        if(!this.$info) return;

        let that = this;
        this.$info.show();

        // delay registering click handler, otherwise it will be
        // instantly triggered when showInfo was called in another click handler
        window.setTimeout(() => {
            $(document).one('click', () => {
                that.hideInfo();
            });
        }, 100);
    }

    hideInfo() {
        if(!this.$info) return;

        this.$info.hide();
    }

    addError(error) {
        let that = this;

        this.$error = $('<div>', { class: "input-group__error"}).html(error);

        let $infoIcon = createIcon('ic-chat').addClass('input-group__icon input-group__icon--error');

        this.$element.append(this.$error);
        this.setIcon($infoIcon);

        this.$input.addClass('input-group__input--error');
    }
}
