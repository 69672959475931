import $ from 'jquery';
import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        let that = this;

        this.$modal = $(this.element);
        this.$content = this.$modal.find('.modal__content');

        this.close();

        $(document).click((event) => {
            if (!$(event.target).closest(this.$content).length) {
                that.close();
            }
        });
    }

    open() {
        this.$modal.removeClass("modal--closed");
    }

    close() {
        this.$modal.addClass("modal--closed");
    }
}
