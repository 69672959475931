import $ from 'jquery';
import { Controller } from "stimulus";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css'
import createIcon from '../../js/icon';

export default class extends Controller {
    isInThumbnailState = true;

    connect() {
        let that = this;

        this.$element = $(this.element);

        this.videoSrc = this.data.get('src');
        this.videoType = this.data.get('type');

        this.$video = $('<video>', { class: 'video__video', id: 'mediaVideo' }).attr({
            controls: false,
            playsinline: true
        });
        this.$video.append(
            $('<source>').attr({ src: this.videoSrc, type: this.videoType })
        );

        this.$playButton = $('<div>', {class: 'video__play-button'});
        this.$playButton.append(
            createIcon('ic-play').addClass('video__play-icon')
        );

        this.$video.on('contextmenu', () => { return false; });
        this.$playButton.on('click', () => {
           that.play();
        });

        this.$element.html('');
        this.$element.append(this.$video);
        this.$element.append(this.$playButton);

        window.setTimeout(() => {
            that.player = new Plyr('#mediaVideo', {
                enabled: true,
                disableContextMenu: true,
                controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
            });

            that.player.on('play', () => {
                that.$playButton.hide();
                $('.wrapper__main').scrollTop(0);

                if(this.isInThumbnailState) {
                    this.isInThumbnailState = false;
                    that.$element.find('.plyr__controls').show();
                    that.$element.closest('.medium__header').addClass('medium__header--no-overlay')
                }
            });

            that.player.on('pause', () => {
                that.$playButton.show();
            });

            that.player.on('ready', () => {
                that.$element.find('.plyr__controls').hide();
            });

        }, 100);
    }

    toggle() {
        if(this.$video.prop('paused')) return this.play();

        return this.pause();
    }

    play() {
        this.player.play();
    }

    pause() {
        this.player.pause();
    }
}
