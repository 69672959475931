import $ from 'jquery';
import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        let that = this;

        this.$container = $(this.element);

        this.tabs = [];

        let $links = this.$container.find('.tabs__link');

        $links.each((index, link) => {
            let $link = $(link);

            let controlsId = $link.attr('aria-controls');
            let $tab = that.$container.find('.tabs__section#' + controlsId);

            let item = {
                name: controlsId,
                $link: $link,
                $tab: $tab
            };

            $link.on('click', (event) => {
                event.preventDefault();
                that.showTab(controlsId);
            });

            that.tabs.push(item);
        });

        if(this.tabs.length) {
            // reset state in case init state is incorrect in the html
            let activeTab = this.tabs[0].name; // fallback to first tab when no tab is selected

            $.each(this.tabs, (index, item) => {
                if(item.$link.attr('aria-selected') === 'true') {
                    activeTab = item.name;
                }
            });

            this.showTab(activeTab);
        }
    }

    disconnect() {
        $.each(this.tabs, (index, item) => {
            item.$link.off('click');
        });

        this.tabs = [];
    }

    showTab(tabname) {
        let that = this;

        $.each(this.tabs, (index, item) => {
            that.setTabState(item, item.name === tabname);
        });
    }

    setTabState(item, is_active) {
        item.$link.attr('aria-selected', is_active ? 'true' : 'false');

        if(item.$tab) {
            item.$tab.attr('aria-hidden', is_active ? 'false' : 'true');
        }
    }
}
