import BaseController from "../base_controller"

export default class extends BaseController {
  static targets = [ "entries" ];
  static values = { nextPage: Number,
                    currentPage: Number };

  connect() {
    this.currentLoadPromise = null;
    this.currentPageValue = 1;
    this.nextPageValue = this.currentPageValue + 1;
    this.getScrollObserver().on('scroll', () => this.scroll());
  }

  disconnect() {
    let scrollObserver = this.getScrollObserver();
    // the scroll observer may have already been disconnected
    if (scrollObserver === null) { return }
    // but perhaps not for XHR requests
    scrollObserver.off('scroll', () => this.scroll());
  }

  getScrollContainer() {
    return document.getElementById('scrolling-container');
  }

  getScrollObserver() {
    return this.getControllerFor(this.getScrollContainer(), 'components--scroll-observer');
  }

  scroll() {
    if(this.currentLoadPromise !== null) return;
    if(this.currentPageValue === this.nextPageValue) return;
    if(!this._hasScrolledToBottom()) return;

    this.currentLoadPromise = this._loadNextPage();
    this.currentLoadPromise
        .catch(console.error)
        .finally(() => this.currentLoadPromise = null);
  }

  _hasScrolledToBottom() {
    let container = this.getScrollContainer();
    let totalContainerHeight = container.scrollHeight;
    let visibleHeight = container.offsetHeight;
    let scrollOffsetFromTop = container.scrollTop;
    let remainingHeight = totalContainerHeight - visibleHeight - scrollOffsetFromTop;

    return remainingHeight <= 800;
  }

  async _loadNextPage() {
    const params = new URLSearchParams(window.location.search);
    params.set("page", this.nextPageValue);

    const nextUrl = new URL(window.location.href);
    nextUrl.search = params;

    const nextJson = await this._fetchJson(nextUrl);
    // update current page counter only if successful
    this.currentPageValue = this.nextPageValue;
    if(!nextJson.entries) return;

    this.nextPageValue++;
    this.entriesTarget.insertAdjacentHTML('beforeend', nextJson.entries);
  }

  async _fetchJson(url) {
    let options = {
      "headers": {
        "Accept": "application/json"
      }
    };

    const response = await fetch(url, options);

    return await response.json();
  }
}
