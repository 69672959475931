import $ from 'jquery';
import { Controller } from "stimulus"

import EventListenerMixin from '../js/mixins/event_listener_mixin';

export default class extends EventListenerMixin(Controller) {
    getControllerFor(node, identifier) {
        return this.application.getControllerForElementAndIdentifier(
            node, identifier
        );
    }

    getControllerForTarget(targetName, identifier) {
        let fullTargetName = targetName;

        if(!fullTargetName.endsWith('Target')) {
            fullTargetName += "Target";
        }

        let hasTargetName = "has" + (fullTargetName.charAt(0).toUpperCase() + fullTargetName.slice(1));

        if(!this[hasTargetName]) return;

        return this.getControllerFor(this[fullTargetName], identifier);
    }
}
