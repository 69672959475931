import Cookies from 'js-cookie'

function CookieConsent() {
    this.onConsentResolves = [];

    this.hasConsented = function() {
        return Cookies.get('schwabe_cookies_consented') === 'true'
    };

    this.giveConsent = function() {
        Cookies.set('schwabe_cookies_consented', 'true', { expires: 365 });

        this.onConsentResolves.forEach((resolve) => resolve());
        this.onConsentResolves = [];
    };

    this.onConsent = function() {
        return new Promise((resolve, reject) => {
            if(this.hasConsented()){
                resolve();
            } else {
                this.onConsentResolves.push(resolve);
            }
        });
    }
}

export default new CookieConsent(); // export singleton;
