import $ from 'jquery';
import moment from 'moment';
import Chatbox from './chat/chatbox';
import UnreadBadge from './chat/unreadBadge';

import ApiSource from './chat/dataSource/apiSource';
import DataSource from './chat/dataSource/dataSource';

import Message from './chat/dataSource/message';
import I18n from './util/i18n.js.erb';

let baseUrl = "/web/chat";

let apiSource = new ApiSource(baseUrl);
let welcomeSource = new DataSource([
    new Message({
        id: 0,
        text: I18n.t('web.chat.show.welcome_message'),
        senderType: "contactPerson",
        createdAt: moment().format(),
        isRead: true,
        sender: {},
        medium: null
    })
]);

let badgeConfig = {
    apiUrl: baseUrl + "/messages/unread",
    pollingInterval: 10000
};

let chatboxApiConfig = {
    dataSource: apiSource,
    pollingInterval: 10000
};

let chatboxWelcomeConfig = {
    dataSource: welcomeSource,
    pollingEnabled: false,
    inputEnabled: false,
    loadMore: false
};

let components = [];

$(document).on('turbolinks:load', () => {
    components.forEach((component) => component.destroy());
    components = [];

    $('.js-chat-link').each((index, element) => {
        let badge = new UnreadBadge(element, badgeConfig);
        badge.initialize();

        components.push(badge);
    });

    $('.chat').each((index, element) => {
        let $element = $(element);
        let chat;

        if($element.attr('data-show-welcome') === 'true') {
            chat = new Chatbox(element, chatboxWelcomeConfig);
        } else {
            chat = new Chatbox(element, chatboxApiConfig);
        }

        chat.initialize();
        components.push(chat);
    })
});
