import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
    static targets = [ "contactPersonImage", "phoneNumberModal", "returnCallModal" ];

    connect() {
        let that = this;

        this.gender = this.data.has('gender') ? this.data.get('gender') : '';

        this.$contactPersonImage = $(this.contactPersonImageTarget);
        this.$contactPersonImage.on('error', () => {
            let fallbackClass = 'contact-person__fallback-image';

            if(that.gender === 'male') {
                fallbackClass += ' contact-person__fallback-image--male';
            }

            that.$contactPersonImage.closest('.contact-person__image-wrapper').addClass(fallbackClass);
        });
    }

    showModal(e) {
        if(e && e.stopPropagation) e.stopPropagation();

        let modalController = this.application.getControllerForElementAndIdentifier(
            this.phoneNumberModalTarget, "components--modal"
        );
        modalController.open();
    }

    confirmReturnCallModal(e){
        if(e && e.stopPropagation) e.stopPropagation();

        let modalController = this.application.getControllerForElementAndIdentifier(
            this.returnCallModalTarget, "components--modal"
        );
        modalController.open();
    }
}
