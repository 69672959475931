import $ from 'jquery';
import moment from 'moment';
import '../util/i18n.js.erb';

import MessageInput from './messageInput';
import MessageContainer from './messageContainer';

export default function Chatbox(containerElement, configuration) {
    let self = this;
    let loadMore = true;
    let checkForNew = true;

    let checkNewInterval = null;

    let config = $.extend(true, {
        dataSource: null,
        pollingInterval: null,
        pollingEnabled: true,
        inputEnabled: true,
        loadMore: true
    }, configuration || {});

    this.initialize = function() {
        this.$container       = $(containerElement);
        this.dataSource       = config.dataSource;
        this.messageContainer = new MessageContainer(this.$container.find('.chat__message-container'));
        this.messageInput     = new MessageInput(this.$container.find('.chat__message-input-wrapper'));

        this.oldestMessageMoment = moment();
        this.newestMessageMoment = this.oldestMessageMoment;

        this.messageContainer.onMessageClick = (message) => {
            if(!message.hasMediumAttached()) return;

            self.messageInput.startReplyToMedium(message.medium);
        };

        this.messageContainer.onLoadMore = () => self.loadMoreMessages();

        this.messageInput.onMessageSend = (text, medium) => self.postNewMessage(text, medium);

        this.messageContainer.initialize();
        this.messageInput.initialize();
        this.dataSource.initialize();

        this.loadMoreMessages();

        if(config.pollingEnabled) {
            checkNewInterval = window.setInterval(() => self.checkForNewMessages(), config.pollingInterval);
        }

        if(!config.loadMore) {
            this.messageContainer.stopLoadMore();
        }

        if(!config.inputEnabled) {
            this.messageInput.disable();
        }
    };

    this.destroy = function() {
        window.clearInterval(checkNewInterval);
    };

    this.loadMoreMessages = function () {
        if(!loadMore) return;
        loadMore = false;

        this.dataSource.getMessageHistory(this.oldestMessageMoment)
            .then((data) => {
                let messages = data.messages;
                let canLoadMore = data.canLoadMore;

                if(!canLoadMore) {
                    self.messageContainer.stopLoadMore();
                    loadMore = false;
                }

                if(messages.length === 0) return;

                self.oldestMessageMoment = messages[messages.length - 1].getMoment();
                self.messageContainer.addMessages(messages)
                                     .then(() => loadMore = true);
            })
            .catch(() => {
               console.log('Failed to query for message history', arguments);
            });
    };

    this.checkForNewMessages = function () {
        if(!checkForNew) return;
        checkForNew = false;

        this.dataSource.getRecentMessages(this.newestMessageMoment)
            .then((data) => {
                let messages = data.messages;

                if(messages.length === 0) return;

                self.newestMessageMoment = messages[0].getMoment();
                self.messageContainer.addMessages(messages);
            })
            .catch(() => {
                console.log('Failed to query for new messages', arguments);
            })
            .finally(() => checkForNew = true);
    };

    this.postNewMessage = function (text, medium) {
        checkForNew = false;

        this.dataSource
            .postNewMessage(text, medium)
            .then(() => {
                checkForNew = true;
                self.checkForNewMessages();
            })
            .catch(() => checkForNew = true);
    }
}
