import $ from 'jquery';
import { Controller } from "stimulus";
import CookieConsent from '../../js/util/cookie_consent';

export default class extends Controller {
    static targets = [ 'okButton' ];

    connect() {
        this.$okButton = $(this.okButtonTarget);
        this.$okButton.on('click', () => this.consent());

        CookieConsent.onConsent().then(() => this.hide());
    }

    consent() {
        CookieConsent.giveConsent();
    }

    hide() {
        $(this.element).hide();
    }
}
