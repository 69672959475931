import $ from 'jquery';

export default function createIcon(iconName) {
    let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + iconName);
    svgElem.appendChild(useElem);

    return $(svgElem)
}
