export default class ManagedCollection {
    constructor(collection) {
        this.collection = collection || [];
    }

    get length() {
        return this.collection.length;
    }

    map(...args) {
        return this.collection.map(...args);
    }

    forEach(...args) {
        return this.collection.forEach(...args);
    }

    setCollection(collection) {
        function findById(collection, id) {
            for(let i = 0; i < collection.length; i++) {
                if(collection[i].id === id) return collection[i];
            }

            return null;
        }

        let updates = [];
        let additions = [];
        let deletions = [];

        let previousCollection = this.collection;
        let newCollection = [];

        collection.forEach((currentItem) => {
            let previousItem = findById(previousCollection, currentItem.id);

            if(previousItem) {
                newCollection.push(previousItem);
                updates.push([previousItem, currentItem]);
            } else {
                newCollection.push(currentItem);
                additions.push(currentItem);
            }
        });

        previousCollection.forEach((previousItem) => {
            if(!findById(collection, previousItem.id)) {
                deletions.push(previousItem);
            }
        });

        this.collection = newCollection;

        return {
            updates: updates,
            additions: additions,
            deletions: deletions
        }
    }
}
