import moment from 'moment';

import Message from './message';
import generateFakeText from '../util/generateFakeText'

export default function DataSource(chatData) {
    let fakeChat = chatData || [];
    let MSGID = 0;

    this.initialize = function () { };

    this.getRecentMessages = function (after) {
        return new Promise((resolve) => {
            let messages = [];

            for(let i = 0; i < fakeChat.length; i++) {
                if(fakeChat[i].getMoment().isAfter(after)) {
                    messages.push(fakeChat[i]);
                } else {
                    break;
                }
            }

            resolve({
                messages: messages,
                canLoadMore: false
            });
        });
    };

    this.getMessageHistory = function (before) {
        return new Promise((resolve) => {
            let messages = [];
            let canLoadMore = false;

            for(let i = 0; i < fakeChat.length; i++) {
                if(fakeChat[i].getMoment().isBefore(before)) {
                    messages.push(fakeChat[i]);

                    if(messages.length >= 5) {
                        canLoadMore = true;
                        break;
                    }
                }
            }

            resolve({
                messages: messages,
                canLoadMore: canLoadMore
            });
        });
    };

    this.postNewMessage = function (text, replyToMedium) {
        return new Promise((resolve) => {
            let message = new Message();

            message.id = MSGID++;
            message.createdAt = moment().format();
            message.isRead = true;
            message.senderType = "user";
            message.medium = replyToMedium;
            message.text = text;

            fakeChat.unshift(message);
            resolve(message);
        });
    }
}
