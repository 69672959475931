import $ from "jquery";
import fallbackAttachment from 'images/chat/missing_image.png';

export default function MessageInput(containerElement) {
    let self = this;
    let enabled = true;

    this.onMessageSend = $.noop;

    this.initialize = function() {
        this.$container        = $(containerElement);
        this.$input            = this.$container.find('.input-group__input');
        this.$submit           = this.$container.find('.chat__submit');
        this.$replyReference   = this.$container.find('.chat__message-reference');

        if(this.$replyReference.length) {
            this.$replyClose       = this.$replyReference.find('.chat__message-reference-close');
            this.$replyImage       = this.$replyReference.find('.chat__message-reference-image');
            this.replyToMedium     = { id: this.$replyReference.attr('data-reply-to-medium') };

            this.$replyClose.on('click', () => self.stopReplyToMedium());
            this.$replyImage.on('error', () => {
                self.$replyImage.attr('src', fallbackAttachment);
            });
        }


        this.$submit.on('click', () => {
            if(!enabled) return;

            let text = self.$input.val();
            if(text === "") return;

            self.onMessageSend(text, self.replyToMedium);
            self.clear();
        });

        this.$input.keypress((e) => {
            if (e.which === 13 && !e.shiftKey) {
                self.$submit.click();

                return false;
            }
        });
    };

    this.disable = function() {
        enabled = false;

        this.$input.attr('disabled', 'true');
        this.$submit.attr('disabled', 'true');
    };

    this.clear = function() {
        this.$input.val("");
        this.stopReplyToMedium();
    };

    this.stopReplyToMedium = function() {
        this.replyToMedium = null;
        this.$replyReference.hide();
    };
}
