import $ from "jquery";
import I18n from '../util/i18n.js.erb';

export default function MessageContainer(containerElement) {
    let self = this;
    let loadMore = true;
    let loadMoreScrollThreshold = 100;

    this.onMessageClick = $.noop;
    this.onLoadMore     = $.noop;

    this.initialize = function() {
        this.$container = $(containerElement);
        this.$loader = this.$container.find('.chat__loader');

        if(this.$loader.length) {
            loadMoreScrollThreshold = this.$loader.height() + this.$loader.position().top;
        }

        this.partitionManager = new DayPartitionManager(containerElement);
        this.partitionManager.onMessageClick = this.onMessageClick;
        this.partitionManager.initialize();

        this.latestMessageMoment = null;

        this.$container.on('scroll', () => self.checkLoadMore());
    };

    this.getScroll = function() {
        return this.$container.scrollTop();
    };

    this.scrollToTop = function() {
        this.scrollTo(0);
    };

    this.scrollToBottom = function() {
        this.scrollTo(this.$container[0].scrollHeight);
    };

    this.stopLoadMore = function () {
        loadMore = false;
        self.$loader.hide();
    };

    this.checkLoadMore = function() {
        if(!loadMore) return;
        if(this.getScroll() > loadMoreScrollThreshold) return;

        this.onLoadMore();
    };

    this.scrollTo = function(position) {
        let loadMoreState = loadMore;
        loadMore = false;

        self.$container.scrollTop(position);

        loadMore = loadMoreState;
        self.checkLoadMore();
    };

    this.addMessages = function (messages) {
        let mostRecentMoment = null;

        messages.forEach((message) => {
            if(!mostRecentMoment || mostRecentMoment.isBefore(message.getMoment())) {
                mostRecentMoment = message.getMoment();
            }
        });

        let onMessagesAdded;

        // When there is a more recent message:
        // scroll to bottom, when not  keep scroll position.
        if(mostRecentMoment.isBefore(this.latestMessageMoment)) {
            let capturedScrollPosition = this.captureScrollPosition();

            onMessagesAdded = () => self.restoreScrollPosition(capturedScrollPosition);
        } else {
            this.latestMessageMoment = mostRecentMoment;

            onMessagesAdded = () => self.scrollToBottom();
        }

        return this.partitionManager.addMessages(messages).then(onMessagesAdded);
    };
    
    this.getVisibleMessages = function () {
        let visibleHeight = this.$container.height();

        return this.partitionManager.getAllMessages().filter(($message) => {
            let top = $message.position().top;
            let height = $message.height();
            let midPoint = top + height/2;

            return midPoint > 0 && midPoint < visibleHeight;
        });
    };

    this.captureScrollPosition = function () {
        let visibleMessages = this.getVisibleMessages();
        if(visibleMessages.length === 0) return null;

        // Choose random visible message to use as reference for scroll position
        let $randomMessage = visibleMessages[Math.floor(Math.random() * visibleMessages.length)];

        return {
            messageToKeepInView: $randomMessage,
            oldPosition: $randomMessage.position().top
        };
    };

    this.restoreScrollPosition = function (capturedScrollPosition) {
        if(!capturedScrollPosition) return this.scrollToBottom();

        let $message = capturedScrollPosition.messageToKeepInView;

        let oldPosition = capturedScrollPosition.oldPosition;
        let newPosition = $message.position().top;

        this.scrollTo(this.getScroll() + (newPosition - oldPosition));
    };
}

function DayPartitionManager(containerElement) {
    let self = this;

    /*
      Array of all partitions, ordered ascending by the date
     */
    let partitions = [];

    this.onMessageClick = $.noop();

    this.initialize = function () {
        this.$container = $(containerElement);
    };

    this.getAllMessages = function () {
        return Array.prototype.concat(...partitions.map((p) => p.getAllMessages()));
    };

    this.addMessage = function(message) {
        return new Promise((resolve) => {
            let partition = self.findOrCreatePartitionForDay(message.getMoment());
            let renderedMessage = partition.renderMessage(message);

            renderedMessage.on('click', () => self.onMessageClick(message));

            // Wait 100ms to let the DOM finish the insertion of the new message.
            // If this turns out to be too much or too little, we could try to use the modern MutationObserver.
            setTimeout(resolve, 100);
        });
    };

    this.addMessages = function(messages){
        return Promise.all(
            messages.map((message) => self.addMessage(message))
        );
    };

    this.findOrCreatePartitionForDay = function (messageMoment) {
        return this.findPartitionForDay(messageMoment) || this.createPartitionForDay(messageMoment);
    };

    this.findPartitionForDay = function (messageMoment) {
        let startOfDay = messageMoment.startOf('day');

        for(let i = 0; i < partitions.length; i++) {
            if(partitions[i].startOfDay.isSame(startOfDay)) return partitions[i];
        }
    };

    this.createPartitionForDay = function(messageMoment) {
        let partition = new DayPartition(messageMoment);
        partition.initialize();

        let insertBefore = null;

        for(let i = 0; i < partitions.length; i++) {
            if(partition.startOfDay.isBefore(partitions[i].startOfDay)) {
                insertBefore = i;
                break;
            }
        }

        if(insertBefore !== null) {
            partition.$partitionContainer.insertBefore(partitions[insertBefore].$partitionContainer);
            partitions.splice(insertBefore, 0, partition);
        } else {
            this.$container.append(partition.$partitionContainer);
            partitions.push(partition);
        }

        return partition;
    };
}

function DayPartition(messageMoment) {
    /*
      Array of all message DOM elements, ordered ascending by their timestamp
     */
    let messages = [];

    this.initialize = function () {
        this.startOfDay = messageMoment.startOf('day');

        let date = this.startOfDay.calendar(null, {
            sameDay: '[' + I18n.t('time.today') + ']',
            lastDay: '[' + I18n.t('time.yesterday') + ']',
            nextDay : 'LL',
            lastWeek : 'LL',
            nextWeek : 'LL',
            sameElse: 'LL'
        });

        this.$labelElement       = $('<div>', {class: 'badge badge--grey'}).html(date);
        this.$partitionContainer = $('<div>', {class: 'chat__message-partition'}).append(this.$labelElement);
    };

    this.getAllMessages = function() {
        return messages;
    };

    this.renderMessage = function(message) {
        let renderedMessage = message.render();
        let messageTimestamp = message.getMoment().unix();

        renderedMessage.attr('data-timestamp', messageTimestamp);

        let insertBefore = null;

        for(let i = 0; i < messages.length; i++) {
            if(messageTimestamp < messages[i].attr('data-timestamp')) {
                insertBefore = i;
                break;
            }
        }

        if(insertBefore !== null) {
            renderedMessage.insertBefore(messages[insertBefore]);
            messages.splice(insertBefore, 0, renderedMessage);
        } else {
            messages.push(renderedMessage);
            this.$partitionContainer.append(renderedMessage);
        }

        return renderedMessage;
    };
}
