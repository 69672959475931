import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import createIcon from '../../js/icon';

import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        this.$element = $(this.element);
        this.previouslySelectedIds = this.selectedIds;

        this.initializeSelect2();
    }

    disconnect() {
        this.$element.select2('destroy');
    }

    get placeholder() {
        return this.$element.attr('placeholder')
    }

    get isMultiple() {
        return this.$element.attr('multiple');
    }

    get submitsOnClose() {
        if(!this.data.has('submit-on-close')) return false;

        return this.data.get('submit-on-close') === 'true';
    }

    get selectedIds() {
        return this.$element.val();
    }

    get select2() {
        return this.$element.siblings('.select2');
    }

    get select2Selection() {
        return this.select2.find('.select2-selection');
    }

    get select2ResultList() {
        let id = this.$element.attr('id');

        return $('#select2-' + id + '-results');
    }

    checkSubmitOnClose() {
        if(!this.submitsOnClose) return;
        if(arraysEqual(this.previouslySelectedIds, this.selectedIds)) return;

        this.submit();
    }

    submit() {
        this.$element.closest("form").submit();
    }

    clear() {
        this.$element.val(null).trigger('change');

        if(!this.select2ResultList.length) return;

        let selectedOptions = this.select2ResultList.find('li[aria-selected="true"]');

        // workaround as select2 does not update the highlight state before closing the dropdown,
        // when options are programmatically unselected
        selectedOptions.removeClass('select2-results__option--highlighted');
        selectedOptions.attr('aria-selected', false);
    }

    initializeSelect2() {
        if(this.$element.data('select2')) return;

        this.$element.select2({
            placeholder: this.placeholder,
            theme: 'schwabe',
            width: '100%',
            multi: this.isMultiple,
            closeOnSelect: !this.isMultiple,
            minimumResultsForSearch: 50
        });

        this.$element.on('select2:close', () => {
            this.customizeSelectionList();

            this.checkSubmitOnClose();
        });

        this.$element.on('select2:select select2:unselect', () => {
            // if the meta option with id '-1' is selected, clear all
            if (this.selectedIds.indexOf('-1') >= 0) {
                this.clear();
            }

            this.customizeSelectionList();
        });

        if(this.data.has("rendered-class-additions")) {
            let select2 = this.$element.siblings('.select2');
            select2.find('.select2-selection__rendered').addClass(this.data.get('rendered-class-additions'));
        }

        this.customizeSelectionList();
    }

    customizeSelectionList() {
        let dropdownArrowIcon = createIcon('ic-dropdown-arrow').addClass('select2-selection__arrow');

        if (this.selectedIds.length > 0) {
            this.select2.addClass('select2--selected');
        } else {
            this.select2.removeClass('select2--selected')
        }

        if(this.isMultiple) {
            let selectionList = this.select2.find('.select2-selection__rendered');
            let text = this.placeholder;

            if (this.selectedIds.length > 0) {
                text += ' (' + this.selectedIds.length + ')';
            }

            selectionList.html("<li>" + text + "</li>");

            this.select2Selection.append(dropdownArrowIcon);
        } else {
            let spanArrow = selection.find('span.select2-selection__arrow');

            if(spanArrow) {
                spanArrow.replaceWith(dropdownArrowIcon);
            }
        }
    }
}

function arraysEqual(arr1, arr2) {
    if(arr1.length !== arr2.length)
        return false;
    for(let i = arr1.length; i--;) {
        if(arr1[i] !== arr2[i])
            return false;
    }

    return true;
}
