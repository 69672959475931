import $ from 'jquery';
import '../../util/csrf-token';

import Message from './message';

function RetryingPromise(executor) {
    return new Promise((resolve, reject) => {
        executor(resolve, () => {
           executor(resolve, reject);
        });
    });
}

function toMessage(json) {
    let message = new Message();

    message.id         = json['id'];
    message.createdAt  = json['created_at'];
    message.text       = json['text'];
    message.isRead     = json['is_read'];
    message.senderType = json['sender_type'];

    if(json['sender']) {
        let sender = {};

        sender.type            = json['sender']['type'];
        sender.id              = json['sender']['id'];
        sender.firstName       = json['sender']['firstname'];
        sender.lastName        = json['sender']['lastname'];
        sender.title           = json['sender']['title'];
        sender.profileImageUrl = json['sender']['profile_image_url'];
        sender.gender          = json['sender']['gender'];

        message.sender = sender
    } else {
        message.sender = null;
    }

    if(json['medium']) {
        let medium = {};

        medium.type              = json['medium']['type'];
        medium.id                = json['medium']['id'];
        medium.title             = json['medium']['title'];
        medium.description       = json['medium']['description'];
        medium.thumbnailUrl      = json['medium']['thumbnail_url'];
        medium.thumbnailMimeType = json['medium']['thumbnail_mime_type'];
        medium.thumbnailName     = json['medium']['thumbnail_name'];

        message.medium = medium;
    } else {
        message.medium = null;
    }

    return message;
}

function processApiResult(data) {
    return {
        messages: data['messages'].map(toMessage),
        canLoadMore: data['can_load_more']
    }
}

export default function ApiSource(baseUrl) {
    let recentMessagesUrl  = baseUrl + "/messages/recent";
    let messagesHistoryUrl = baseUrl + "/messages/history";
    let newMessageUrl      = baseUrl + "/messages";

    this.initialize = function () { };

    this.getRecentMessages = function (after) {
        return new RetryingPromise((resolve, reject) => {
            $.get(recentMessagesUrl, { after: after.format() })
                .done((data) => resolve(processApiResult(data)))
                .fail(reject)
        });
    };

    this.getMessageHistory = function (before) {
        return new RetryingPromise((resolve, reject) => {
            $.get(messagesHistoryUrl, { before: before.format() })
                .done((data) => resolve(processApiResult(data)))
                .fail(reject)
        });
    };

    this.postNewMessage = function (text, replyToMedium) {
        let params = {
            text: text
        };

        if(replyToMedium) params.medium_id = replyToMedium.id;

        return new RetryingPromise((resolve, reject) => {
            $.post(newMessageUrl, params)
                .done((data) => resolve(toMessage(data)))
                .fail(reject)
        });
    }
}
